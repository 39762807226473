import styled from 'styled-components';

import baseTheme, { ThemeProps } from '@lib/theme';
import FlexWrapper from './FlexWrapper';

export type ColorOption =
  | 'lightGreen'
  | 'green'
  | 'darkGreen'
  | 'brown'
  | 'default';
type ShapeOption = 'waves' | 'ripped' | 'none';

export interface SectionProps {
  children: React.ReactNode;
  color?: ColorOption;
  startShape?: ShapeOption;
  endShape?: ShapeOption;
  isHero?: boolean;
  fullScreenBackground?: boolean;
  contentMaxWidth?: string;
  contentWidth?: string;
  innerAlign?: string;
  ignoreTrailingSpacing?: boolean;
  ignoreOuterSpacing?: boolean;
  gap?: number;
  padding?: string;
}

export const colorMap: Record<ColorOption, string> = {
  lightGreen: baseTheme.colors.card.lightGreen,
  green: baseTheme.colors.card.green,
  darkGreen: baseTheme.colors.card.darkGreen,
  brown: baseTheme.colors.card.brown,
  default: baseTheme.colors.backgroundColor,
};

const shapeSize: Record<ShapeOption, string> = {
  waves: '15px',
  ripped: '33px',
  none: '0px',
};

const pagePadding = '3rem';
const spacing = '2rem';

type OuterProps = ThemeProps & SectionProps;

const Wrapper = styled(FlexWrapper)
  .attrs({ direction: 'column' })
  .withConfig({
    shouldForwardProp: (p) =>
      ![
        'color',
        'startShape',
        'endShape',
        'isHero',
        'fullScreenBackground',
        'padding',
        'ignoreOuterSpacing',
        'ignoreTrailingSpacing',
      ].includes(p),
  })<OuterProps>(
  ({
    theme,
    color = 'default',
    startShape = 'none',
    endShape = 'none',
    isHero = false,
    fullScreenBackground = true,
    ignoreTrailingSpacing = false,
    ignoreOuterSpacing = false,
    padding,
  }) => `
    width: ${fullScreenBackground ? '100vw' : '100%'};
    position: relative;
    left: calc(-50vw + 50%);
    background: ${colorMap[color]};
    z-index: ${theme.zIndex.default};
    gap: 1rem;
    display: flex;
    justify-content: center;
    align-items: center; 
    padding: ${
      padding ? padding : color === 'default' ? `0 ${spacing}` : `${spacing}`
    };
    ${isHero ? `padding-top: ${pagePadding};` : ''}
    margin-bottom: calc(${
      ignoreOuterSpacing || ignoreTrailingSpacing ? '0px' : spacing
    } + ${shapeSize[endShape]});
    margin-top: ${
      isHero
        ? `-${pagePadding}`
        : `calc(${ignoreOuterSpacing ? '0px' : spacing} + ${
            shapeSize[startShape]
          })`
    };
    

    ${
      startShape === 'none'
        ? ''
        : `
      &:before {
        content: '';
        height: ${shapeSize[startShape]};
        width: 100vw;
        background: ${colorMap[color]};
        mask-image: url('/icons/${startShape}.svg');
        mask-size: cover;
        top: -${shapeSize[startShape]};
        position: absolute;
      }`
    }

    ${
      endShape === 'none'
        ? ''
        : `  
      &:after {
        content: '';
        height: ${shapeSize[endShape]};
        width: 100vw;
        background: ${colorMap[color]};
        mask-image: url('/icons/${endShape}.svg');
        mask-size: cover;
        transform: rotate(180deg);
        bottom: -${shapeSize[endShape]};
        position: absolute;
      }
    `
    }
  `,
);

type InnerProps = ThemeProps &
  Pick<SectionProps, 'contentWidth' | 'contentMaxWidth' | 'innerAlign'> & {
    gap: string;
  };

const InnerWrapper = styled.div.withConfig({
  shouldForwardProp: (p) =>
    !['contentWidth', 'contentMaxWidth', 'innerAlign', 'gap'].includes(p),
})<InnerProps>(
  ({ theme, contentWidth, contentMaxWidth, innerAlign = 'center', gap }) => `
  width: ${contentWidth || '100%'};
  max-width: ${contentMaxWidth || theme.maxWidth};
  display: flex;
  flex-direction: column;
  align-items: ${innerAlign};
  gap: ${gap};
`,
);

export const Section = ({
  contentWidth,
  contentMaxWidth,
  innerAlign,
  children,
  gap = 1,
  ...props
}: SectionProps) => (
  <div>
    <Wrapper {...props}>
      <InnerWrapper
        contentWidth={contentWidth}
        contentMaxWidth={contentMaxWidth}
        innerAlign={innerAlign}
        gap={`${gap}rem`}
      >
        {children}
      </InnerWrapper>
    </Wrapper>
  </div>
);
