import dynamic from 'next/dynamic';

import { useSubscriptions } from '@lib/hooks';
import { isSubscriptionActive } from '@lib/utils.subscription';

const UserHero = dynamic(() => import('./UserHero'));
const SubscriberHero = dynamic(() => import('./SubscriberHero'));

const FrontPageHero = () => {
  const { subscriptions = {}, loading } = useSubscriptions();

  const isSubscriber =
    loading ||
    Object.values(subscriptions).filter(isSubscriptionActive).length > 0;

  return isSubscriber ? <SubscriberHero /> : <UserHero />;
};

export default FrontPageHero;
