import Heading from '@components/Heading';
import Image from '@components/Image';
import Link from '@components/Link';
import { Text } from '@components/Typography';
import { Column, Row } from '@components/wrappers/FlexWrapper';
import { Section } from '@components/wrappers/PageSection';
import {
  useAccount,
  useLocale,
  useSubscriptions,
  useTranslation,
} from '@lib/hooks';
import { capitalize } from '@lib/utils';
import {
  hasCompletedRenewals,
  isSubscriptionActive,
} from '@lib/utils.subscription';
import LINKS from 'constants/links';
import { SiteLocale } from '@types';

const trustpilot = {
  key: 'trustpilot',
  link: LINKS.TRUSTPILOT_REVIEW,
  src: '/icons/star.svg',
};

const consultation = {
  key: 'consultation',
  link: LINKS.CONSULTATION,
  src: '/images/henna.png',
};

const supportPerson: Record<SiteLocale, 'wilma' | 'marikki'> = {
  [SiteLocale.FI]: 'marikki',
  [SiteLocale.EN]: 'wilma',
  [SiteLocale.DA]: 'wilma',
  [SiteLocale.SV]: 'wilma',
};

const CustomerSupport = () => {
  const t = useTranslation();
  const locale = useLocale();
  const { account } = useAccount();
  const { subscriptions = {} } = useSubscriptions();

  const isActiveSubscriber =
    Object.values(subscriptions).filter(isSubscriptionActive)?.length > 0;
  const hasOneCompletedRenewal = hasCompletedRenewals(account, subscriptions);

  const items = [
    { key: 'faq', link: LINKS.FAQ, src: '/icons/que-circled.svg' },
    {
      key: 'contact',
      link: LINKS.MAIL_TO_SUPPORT,
      src: `/images/${supportPerson[locale]}.png`,
      translationProps: { name: capitalize(supportPerson[locale]) },
    },
  ];

  if (isActiveSubscriber && hasOneCompletedRenewal) {
    items.push(trustpilot);
  }

  if (!hasOneCompletedRenewal && !isActiveSubscriber) {
    items.splice(1, 0, consultation);
  } else {
    items.push(consultation);
  }

  return (
    <Section contentMaxWidth="600px" innerAlign="left">
      <Heading align="center">{t('customer_service.heading')}</Heading>
      {items.map(
        ({ key, link, src, translationProps }) =>
          link[locale] && (
            <Row gap="1rem" key={key}>
              <Image src={src} width={48} height={48} minWidth={48} />
              <Column maxWidth="">
                <Text ignoreMargin>
                  {t(`customer_service.${key}.body`, translationProps)}
                </Text>
                <Link underline href={link[locale]}>
                  {t(`customer_service.${key}.link_text`)}
                </Link>
              </Column>
            </Row>
          ),
      )}
    </Section>
  );
};

export default CustomerSupport;
