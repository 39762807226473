import FlexWrapper, { FlexProps } from '@components/wrappers/FlexWrapper';
import styled from 'styled-components';

const PageContainer = styled(FlexWrapper).attrs({
  as: 'article',
})``;

const PageWrapper = ({
  children,
  ...rest
}: { children: React.ReactNode } & FlexProps) => (
  <PageContainer width="100%" padding="2rem 1rem" direction="column" {...rest}>
    {children}
  </PageContainer>
);

export default PageWrapper;
